import { Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ImageItemComponent } from '../../../../shared/components/ui/image-item/image-item.component';

@Component({
  selector: 'app-product-card',
  standalone: true,
  templateUrl: './product-card.component.html',
  imports: [CurrencyPipe, MatSelectModule, ImageItemComponent]
})
export class ProductCardComponent {
  @Input() item!: any;
  @Input() plan!: any;
  @Input() subTotal: number = 0;
  @Input() total: number = 0;
  @Input() tax: number = 0;
  @Input() discount: number = 0;
  @Input() totalTitle = 'Total';
}
